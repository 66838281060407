/* Header Container */
.header {
  display: flex;
  flex-direction: column;
  background-color: #f7e9d7;
  padding: 0px 0px 0px 0px;
  position: relative;
  text-align: left;
}

/* Top Section */
.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0px 0px 0px 0px;
  margin: 0; /* Remove any external margin */
  background-color: #70284c; /* Blueish background */
  border-radius: 1px; 
  box-sizing: border-box; /* Include padding and border in width calculation */
}

/* Header Content */
.header-content {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: flex-end; /* Ensures content aligns at the bottom */
  width: 100%;
  height: 0px;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px;
  position: relative; /* Ensures the child elements like image-content are positioned inside it */
  flex-direction: row; /* Maintains horizontal alignment of text and image */
}

.text-content {
  max-width: 600px;
}

.header-title {
  font-size: 2.4rem;
  font-weight: bold;
  margin: 0;
}

.header-subtitle {
  font-size: 1.2rem;
  color: #333;
  margin-top: 10px;
}

.logo {
  height: 100px;
  max-width: 100%;
  margin-left: 5px;
}

/* Navigation */
.nav {
  display: flex;
  gap: 15px;
}

.nav a {
  color: #ffffff; /* White text for better contrast */
  text-decoration: none; /* Remove underlines */
  font-weight: 700; /* Make text bold */
  font-size: 1.1rem; /* Slightly larger text for emphasis */
  letter-spacing: 0.05em; /* Add subtle spacing between letters */
  text-transform: uppercase; /* Capitalize all text for prominence */
  transition: all 0.3s ease; /* Smooth transition for hover effects */
}

.nav a:hover {
  text-decoration: underline; /* Underline on hover for emphasis */
  color: #cce7ff; /* Light blue text on hover for a softer look */
}

/* Get Started Button */
.get-started-btn {
  background: none;
  border: 2px solid #ffffff; /* White border for visibility */
  color: #ffffff; /* White text for strong contrast */
  padding: 5px 13px;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  margin-right: 5px; /* Add gap between the button and the right edge */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transitions */
}

/* Ensure the inner link inherits button styles */
.get-started-btn a {
  color: #ffffff; /* Enforce white text color */
  text-decoration: none; /* Remove underline */
  font-weight: inherit; /* Match the parent's font weight */
}

/* Hover Effects */
.get-started-btn:hover {
  background-color: #ffffff; /* White background on hover */
  color: #005f99; /* Blue text for button */
  border-color: #ffffff; /* White border remains */
}

.get-started-btn:hover a {
  color: #005f99; /* Ensure link inside button matches hover text color */
}

/* Updated Image Content */
.image-content-left {
  position: absolute; /* Ensures it stays positioned relative to the container */
  bottom: 0; /* Aligns the image to the bottom */
  left: 0; /* Aligns the image to the left */
  width: auto; /* Ensures natural width scaling */
  height: auto;
  max-width: 80px; /* Limits the image size */
  /*max-height: 100%;*/ /* Ensures it fits within the container */
  display: flex;
  justify-content: flex-start; /* Aligns content to the left */
  align-items: flex-end; /* Pushes the content to the bottom */
}
  
/* Adjust dog-image specifics */
.dog-image-left {
  position: relative;
  height: auto;
  max-width: 90%;
  /*max-height: 340px;*/ /* Matches topfold-content's background height */
  object-fit: contain; /* Ensures proper scaling without distortion */
}

.image-content-right {
  position: absolute; /* Ensures it stays positioned relative to the container */
  bottom: 0; /* Aligns the image to the bottom */
  right: 0; /* Aligns the image to the left */
  width: auto; /* Ensures natural width scaling */
  height: auto;
  max-width: 60px; /* Limits the image size */
  /*max-height: 100%;*/ /* Ensures it fits within the container */
  display: flex;
  justify-content: flex-start; /* Aligns content to the left */
  align-items: flex-end; /* Pushes the content to the bottom */
}

/* Adjust dog-image specifics */
.dog-image-right {
  position: relative;
  height: auto;
  max-width: 100%;
  max-height: 340px; /* Matches topfold-content's background height */
  object-fit: contain; /* Ensures proper scaling without distortion */
}

.top-fold-background-image {
  background-image: url('../assets/images/kkdg-tfbg-img.png');
  width: 100%;
  height: 340px;
  background-size: cover; /* Makes the image cover the entire div */
  background-position: center; /* Centers the image in the div */
  background-repeat: no-repeat; /* Prevents repeating if the image is smaller than the div */
}

/* Mobile Styling (Up to 570px) */
@media (max-width: 570px) {
  .header {
    flex-direction: column;
    text-align: center;
    /*padding: 15px;*/
  }

  .header-top {
    flex-direction: column; /* Stack elements vertically */
    align-items: center;
    /*padding: 10px;*/
  }

  .logo {
    height: 75px; /* Smaller logo for mobile */
    margin-bottom: 10px;
  }

  .nav {
    flex-direction: column; /* Stack navigation links */
    gap: 8px;
  }

  .nav a {
    font-size: 1rem; /* Adjust text size for mobile */
  }

  .get-started-btn {
    padding: 8px 15px;
    font-size: 1rem;
    margin-top: 10px 0; /* Add spacing between elements */
  }

  .header-content {
    flex-direction: column; /* Stack content vertically */
    align-items: center;
    height: 0px;
  }

  .text-content {
    max-width: 90%; /* Reduce width for mobile */
    margin-bottom: 15px;
  }

  .dog-image-left, .dog-image-right {
    max-width: 70%; /* Scale down images */
    margin: 10px 0;
  }
}

/* Tablet Styling (570px to 1024px) */
@media (min-width: 570px) and (max-width: 1024px) {
  .header {
    flex-direction: column;
    text-align: center;
    /*padding: 20px;*/
  }

  .header-top {
    flex-direction: row; /* Keep elements in a row */
    justify-content: space-between;
    align-items: center;
    /*padding: 15px;*/
  }

  .logo {
    height: 75px; /* Slightly smaller logo for tablets */
  }

  .nav {
    flex-direction: row; /* Maintain row layout */
    gap: 15px; /* Increase gap for better layout */
  }

  .nav a {
    font-size: 0.8rem; /* Adjust text size */
  }

  .get-started-btn {
    padding: 5px 5px;
    font-size: 0.8rem;
  }

  .header-content {
    flex-direction: row; /* Maintain horizontal layout */
    align-items: center;
    height: 0px;
  }

  .text-content {
    max-width: 70%; /* Reduce width for better layout */
  }

  .dog-image-left, .dog-image-right {
    max-width: 50%; /* Adjust image sizes for tablets */
  }
}
