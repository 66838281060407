/* src/Views/HomePage.css */
.contact-content {
    text-align: justify;
    padding: 5px;
}

.contact-content p {
    font-size: 1.4rem;
    color: #555;
}

.g-map iframe {
    width: 50%; /* Makes the map responsive */
    height: 400px;
    border: none;
    margin-top: 20px;
}

.address {
    font-size: 1.2rem;
    font-weight: bold;
}

.telephone {
    font-size: 1.2rem;
    text-decoration: none; /* Removes underline */
    font-weight: bold; /* Slight emphasis */
    color: #005f99; /* Adjust color for better emphasis */
    background-color: #f1f1f1; /* Match button background for a clean look */
    border: none; /* Ensure the button itself has no border */
    padding: 5px 10px; /* Adjust padding for better spacing */
    border-radius: 5px; /* Slightly rounded corners for modern styling */
    cursor: pointer; /* Change cursor to pointer on hover */
    display: inline-block; /* Ensures it behaves well within text */
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth hover effect */
}

.telephone a {
    text-decoration: none; /* Inherits no underline from parent */
    color: inherit; /* Keeps the button text consistent */
}

.telephone:hover {
    background-color: #005f99; /* Slight color change on hover */
    color: #ffffff; /* Text color on hover */
}

.kkdg-opening-hours {
  background-color: #f7f7f7; /* Light grey background for contrast */
  padding: 10px;
  margin: 10px 0;
  border-radius: 8px;
  max-width: 50%;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.kkdg-opening-hours h2 {
  font-size: 1.8em;
  margin-bottom: 10px;
  color: #333; /* Neutral dark color for headings */
}

.kkdg-opening-hours ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 10px; /* Add spacing between each item */
}

.kkdg-opening-hours li {
  display: flex;
  justify-content: space-between; /* Align text on either side */
  font-size: 1.2em;
  color: #555;
}

.kkdg-opening-hours li span {
  font-weight: bold;
  color: #007bff; /* Highlight times in a pleasing blue */
}


/* Mobile Styling (Up to 570px) */
@media (max-width: 570px) {
  .contact-content {
      text-align: center; /* Center-align text for better readability */
      padding: 10px;
  }

  .contact-content h1 {
        text-align: left;
        font-size: 1.6rem;
  }
    
  .contact-content p {
      font-size: 1.1rem; /* Reduce font size for smaller screens */
      margin: 10px 0; /* Add spacing between paragraphs */
  }
  
  .g-map iframe {
      width: 100%; /* Full width for small screens */
      height: 300px; /* Reduce height for mobile */
  }
  
  .address {
      font-size: 1rem; /* Adjust font size for smaller screens */
  }
  
  .telephone {
      font-size: 1rem; /* Adjust button text size */
      padding: 10px 15px; /* Add padding for touch-friendly buttons */
  }

  .kkdg-opening-hours {
      padding: 10px;
      margin: 10px auto;
      max-width: 90%; /* Utilize more horizontal space */
  }

  .kkdg-opening-hours h2 {
      font-size: 1.4em;
  }

  .kkdg-opening-hours li {
      font-size: 1em;
  }
}
  
/* Tablet Styling (570px to 1024px) */
@media (min-width: 570px) and (max-width: 1024px) {
  .contact-content {
      text-align: justify;
      padding: 20px;
  }

  .contact-content h1 {
        text-align: left;
        font-size: 1.6rem;
  }

  .contact-content p {
      font-size: 1.2rem; /* Adjust font size for better readability */
      margin: 15px 0; /* Add spacing for a clean layout */
  }
  
  .g-map iframe {
      width: 80%; /* Use a smaller width for balance */
      height: 350px; /* Maintain reasonable height */
      margin: 0 auto; /* Center the map */
  }
  
  .address {
      font-size: 1.0rem; /* Slightly larger text for tablets */
  }
  
  .telephone {
      font-size: 1.1rem; /* Slightly larger button text */
      padding: 10px 20px; /* Adjust padding for better touch usability */
  }

  .kkdg-opening-hours {
      padding: 15px;
      margin: 15px auto;
  }

  .kkdg-opening-hours h2 {
      font-size: 1.6em;
  }

  .kkdg-opening-hours li {
      font-size: 1.1em;
  }
}
  
