/* src/views/contents/services/service.css */
.service-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 20px;
  }

.content-txt {
  text-align: justify;
  font-size: 1.2rem;
  color: #555;
}

.content-txt li {
  margin-left: 20px;
}

/* Mobile Styling (Up to 570px) */
@media (max-width: 570px) {
  .service-container {
    flex-direction: column; /* Stack elements vertically */
    padding: 10px; /* Reduce padding for smaller screens */
    gap: 10px; /* Reduce gap for compact layout */
  }

  .content-txt {
    font-size: 1rem; /* Reduce font size for better readability on small screens */
    text-align: left; /* Align text to the left for mobile */
  }

  .content-txt li {
    margin-left: 10px; /* Reduce indentation for mobile */
  }
}

/* Tablet Styling (570px to 1024px) */
@media (min-width: 570px) and (max-width: 1024px) {
  .service-container {
    flex-direction: row; /* Maintain horizontal layout */
    flex-wrap: wrap; /* Ensure wrapping for long content */
    padding: 15px; /* Adjust padding for tablets */
    gap: 15px; /* Moderate gap for better layout */
  }

  .content-txt {
    font-size: 1.1rem; /* Adjust font size for tablets */
    text-align: justify; /* Keep text justified for clean layout */
  }

  .content-txt li {
    margin-left: 15px; /* Moderate indentation for tablet view */
  }
}
