/* src/Views/ServicesPage.css */
.services-content {
    text-align: justify;
    padding: 5px;
    width: 100%;
}

.services-content h1 {
    font-size: 1.8rem;
    color: #555;
    text-align: center;
}

.services-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 20px;
  }

.service-item {
  text-align: center;
  width: 150px;
  position: relative;
  transition: transform 0.2s ease; /* Smooth slide animation */
}

.service-item:hover {
  transform: translateY(-5px); /* Slide up by 5px */
}

.service-item:hover .service-title {
  font-weight: bold; /* Emphasize by making bold */
  color: #000; /* Optional: Change color to emphasize */
}

.service-image {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
    border: 2px solid #ccc; /* Optional: Adds a border */
  }
  
  .service-title {
    font-size: 14px;
    font-weight: 500;
    color: #333;
    margin: 0;
    transition: font-weight 0.2s ease, color 0.2s ease; /* Smooth emphasis transition */
  }
  
  .service-link {
    text-decoration: none;
    color: inherit;
  }
  
  .service-link:hover {
    text-decoration: none;
  }

  .service-link:hover .service-title {
    text-decoration: none; /* Ensure the title text is not underlined */
    font-weight: bold; /* Keep emphasis */
}