/* topfold Container */
.topfold {
  display: flex;
  flex-direction: column;
  background-color: #f7e9d7;
  padding: 0px 0px 0px 0px;
  position: relative;
  text-align: left;
}
  
/* Top Section */
.topfold-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0px 0px 0px 0px;
  margin: 0; /* Remove any external margin */
  background-color: #70284c; /*#005f99;*/ /* Blueish background */
  border-radius: 1px; 
  box-sizing: border-box; /* Include padding and border in width calculation */
}
  
/* topfold Content */
.topfold-content {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: flex-end; /* Ensures content aligns at the bottom */
  width: 100%;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px;
  position: relative; /* Ensures the child elements like image-content are positioned inside it */
  flex-direction: row; /* Maintains horizontal alignment of text and image */
}

/* Text Content with Background Overlay */
.text-content {
  max-width: 600px;
  position: relative;
  z-index: 2; /* Ensure text appears on top of overlay */
  background-color: rgba(133, 13, 13, 0.0); 
  padding: 5px 10px; /* Adjust padding for consistent spacing */
  border-radius: 8px; /* Optional: Rounded edges */
  color: #ffffff; /* White text for contrast */
  text-align: left;
}

/* Title and Subtitle Styling for Readability */
.topfold-title {
    font-size: 2.4rem;
    font-weight: bold;
    margin: 0;
    margin-top: 1px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8); /* Subtle shadow for contrast */
}

  .topfold-subtitle {
    font-size: 1.2rem;
    color: #e0e0e0; /* Slightly lighter color for subtitle */
    margin-top: 10px;
    margin-bottom: 5px; /* Reduced gap for better spacing 
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.8); /* Subtle shadow for contrast */
  }
  
  .logo {
    height: 75px;
    max-width: 100%;
    margin-left: 5px;
  }
  
  /* Navigation */
  .nav {
    display: flex;
    gap: 15px;
  }
  
  .nav a {
    color: #ffffff; /* White text for better contrast */
    text-decoration: none; /* Remove underlines */
    font-weight: 500; /* Make text bold */
    font-size: 1.1rem; /* Slightly larger text for emphasis */
    letter-spacing: 0.05em; /* Add subtle spacing between letters */
    text-transform: uppercase; /* Capitalize all text for prominence */
    transition: all 0.3s ease; /* Smooth transition for hover effects */
  }
  
  .nav a:hover {
    text-decoration: underline; /* Underline on hover for emphasis */
    color: #cce7ff; /* Light blue text on hover for a softer look */
  }
  
  /* Get Started Button */
  .get-started-btn {
    background: none;
    border: 2px solid #ffffff; /* White border for visibility */
    color: #ffffff; /* White text for strong contrast */
    padding: 5px 13px;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    margin-right: 5px; /* Add gap between the button and the right edge */
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transitions */
  }
  
  /* Ensure the inner link inherits button styles */
  .get-started-btn a {
    color: #ffffff; /* Enforce white text color */
    text-decoration: none; /* Remove underline */
    font-weight: inherit; /* Match the parent's font weight */
  }
  
  /* Hover Effects */
  .get-started-btn:hover {
    background-color: #ffffff; /* White background on hover */
    color: #005f99; /* Blue text for button */
    border-color: #ffffff; /* White border remains */
  }
  
  .get-started-btn:hover a {
    color: #005f99; /* Ensure link inside button matches hover text color */
  }
  
  /* Updated Image Content */
  .topfold-image-content-left {
    position: absolute; /* Ensures it stays positioned relative to the container */
    bottom: 0; /* Aligns the image to the bottom */
    left: 0; /* Aligns the image to the left */
    width: auto; /* Ensures natural width scaling */
    height: auto;
    max-width: 50%; /* Limits the image size */
    max-height: 100%; /* Ensures it fits within the container */
    display: flex;
    justify-content: flex-start; /* Aligns content to the left */
    align-items: flex-end; /* Pushes the content to the bottom */
  }
  
  /* Adjust dog-image specifics */
  .dog-image-left {
    position: relative;
    height: auto;
    max-width: 90%;
    /*max-height: 340px;*/ /* Matches topfold-content's background height */
    object-fit: contain; /* Ensures proper scaling without distortion */
  }

  .topfold-image-content-right {
    position: absolute; /* Ensures it stays positioned relative to the container */
    bottom: 0; /* Aligns the image to the bottom */
    right: 0; /* Aligns the image to the left */
    width: auto; /* Ensures natural width scaling */
    height: auto;
    max-width: 50%; /* Limits the image size */
    max-height: 100%; /* Ensures it fits within the container */
    display: flex;
    justify-content: flex-start; /* Aligns content to the left */
    align-items: flex-end; /* Pushes the content to the bottom */
  }
  
  /* Adjust dog-image specifics */
  .dog-image-right {
    position: relative;
    height: auto;
    max-width: 100%;
    max-height: 340px; /* Matches topfold-content's background height */
    object-fit: contain; /* Ensures proper scaling without distortion */
  }
  
  .top-fold-background-image {
    background-image: url('../assets/images/kkdg-tfbg-img.png');
    width: 100%;
    height: 340px;
    background-size: cover; /* Makes the image cover the entire div */
    background-position: center; /* Centers the image in the div */
    background-repeat: no-repeat; /* Prevents repeating if the image is smaller than the div */
  }

  /* Darken Background Image */
  .top-fold-background-image::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4); /* Dark overlay */
    z-index: 1; /* Ensure overlay is behind text */
  }
  
/* Styling for Tablets (768px to 1024px) */
@media (min-width: 768px) and (max-width: 1024px) {
  .topfold {
    flex-direction: column;
    text-align: center; /* Align for better readability */
    padding: 20px; /* Add padding for better spacing */
  }

  .topfold-top {
    flex-direction: row; /* Keep navigation inline for tablets */
    justify-content: space-between;
    align-items: center;
    padding: 15px;
  }

  .logo {
    height: 50px; /* Slightly smaller logo for tablet screens */
    margin-left: 5px;
  }

  .nav {
    flex-direction: row; /* Keep navigation inline for tablets */
    gap: 10px;
  }

  .nav a {
    font-size: 0.8rem; /* Slightly smaller text for tablet navigation */
  }

  .get-started-btn {
    padding: 5px 5px;
    font-size: 0.8rem;
    margin: 0; /* Keep compact spacing */
  }

  .topfold-content {
    flex-direction: row; /* Maintain horizontal layout */
    align-items: center;
    padding: 20px;
  }

  .text-content {
    max-width: 70%; /* Slightly reduce width */
    padding: 10px;
  }

  .topfold-title {
    font-size: 2rem; /* Adjust font size for tablet readability */
  }

  .topfold-subtitle {
    font-size: 1.1rem; /* Adjust subtitle size */
  }

  .topfold-image-content-left,
  .topfold-image-content-right {
    max-width: 40%; /* Adjust image sizes for tablets */
    margin: 0; /* Align images with text */
  }
}

/* Mobile Styling (Up to 768px) */
@media (max-width: 570px) {
  .topfold {
    flex-direction: column;
    text-align: center;
  }

  .topfold-top {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .logo {
    height: 75px; /* Smaller logo */
    margin-bottom: 10px;
  }

  .nav {
    flex-direction: column; /* Stack navigation links */
    gap: 8px;
  }

  .nav a {
    font-size: 0.8rem; /* Adjust text size for mobile */
  }

  .get-started-btn {
    padding: 5px 5px;
    font-size: 0.8rem;
    margin: 10px 0;
  }

  .topfold-content {
    flex-direction: column; /* Stack content vertically */
    align-items: center;
    padding: 10px;
  }

  .text-content {
    max-width: 90%; /* Reduce width for mobile */
    margin-bottom: 15px;
  }

  .topfold-title {
    font-size: 1.8rem; /* Reduce font size */
  }

  .topfold-subtitle {
    font-size: 1rem;
  }

  .topfold-image-content-left,
  .topfold-image-content-right {
    max-width: 70%; /* Scale down images */
    margin: 10px 0;
  }
}

/* Fine-Tuning for Very Small Devices (Below 480px) */
@media (max-width: 480px) {
  .nav a {
    font-size: 0.8rem;
  }

  .get-started-btn {
    font-size: 0.9rem;
  }

  .topfold-title {
    font-size: 1.6rem;
  }

  .topfold-subtitle {
    font-size: 0.9rem;
  }
}
