/* Footer Container */
.footer {
  display: flex;
  flex-direction: column;
  background-color: #70284c;
  padding: 0px 0px 0px 0px;
  position: relative;
  text-align: left;
}

/* Footer Content */
.footer-content {
  display: flex;
  justify-content: space-between; /* Spread elements to edges */
  align-items: center; /* Vertically center the content */
  width: 100%;
  height: 60px;
  padding: 0 20px; /* Add some horizontal padding for spacing */
  box-sizing: border-box; /* Include padding in width calculation */
}

.copyright {
  flex: 1; /* Allow it to take available space on the left */
  text-align: left; /* Align text to the left */
  font-size: 1rem; /* Increased font size for readability */
  color: #ffffff; /* White text for high contrast */
  margin: 0; /* Ensure no extra space */
}

.social {
  flex: 1; /* Allow it to take available space on the right */
  text-align: right; /* Align text to the right */
}

.social a {
  color: #ffffff; /* White color for icon */
  transition: transform 0.3s ease, color 0.3s ease; /* Smooth hover effect */
}

.social a:hover {
  color: #ffcc00; /* Highlight color on hover */
  transform: scale(1.1); /* Slightly enlarge on hover */
}

.text-content {
  flex: 1; /* Center text-content in the middle */
  text-align: center; /* Center the text horizontally */
}

/* Mobile Styling (Up to 570px) */
@media (max-width: 570px) {
  .footer {
    flex-direction: row;
    text-align: center; /* Center-align for better readability */
  }

  .footer-content {
    flex-direction: row; /* Keep content in a single row */
    justify-content: space-between; /* Spread items across the row */
    align-items: center; /* Center items vertically */
    gap: 0; /* Remove extra gap */
  }

  .copyright {
    text-align: left;
    font-size: 0.7rem; /* Adjust font size for smaller screens */
  }

  .text-content {
    flex: 0.7; /* Center text-content in the middle */
    text-align: center; /* Center the text horizontally */
  }

  .social {
    text-align: right; /* Ensure right alignment */
  }

  .social a {
    font-size: 1.2rem; 
    margin: 0; 
  }
}

/* Tablet Styling (570px to 1024px) */
@media (min-width: 570px) and (max-width: 1024px) {
  .footer {
    flex-direction: row; /* Keep footer items in a row */
    justify-content: space-between;
    padding: 20px; /* Add padding for spacing */
  }

  .footer-content {
    flex-direction: row; /* Maintain row layout */
    gap: 10px; /* Add spacing for better layout */
  }

  .copyright {
    text-align: left; /* Align to the left for a desktop-like appearance */
    font-size: 1.1rem; /* Slightly larger font for readability */
  }

  .social {
    text-align: right; /* Align to the right for better spacing */
  }

  .social a {
    font-size: 1.8rem; /* Larger size for tablet view */
    margin: 0 10px; /* Increase spacing for better layout */
  }
}
