/* src/Views/HomePage.css */
.home-content {
    text-align: justify;
    padding: 5px;
}

.home-content p {
    font-size: 1.2rem;
    color: #555;
}

/* Mobile Styling (Up to 570px) */
@media (max-width: 570px) {
    .home-content {
      text-align: center; /* Center-align text for better readability */
      padding: 10px; /* Add padding for smaller screens */
    }
  
    .home-content p {
      font-size: 1rem; /* Reduce font size for mobile */
      margin-bottom: 10px; /* Add spacing between paragraphs */
    }
  
    .telephone {
      font-size: 1rem; /* Adjust button text size */
      padding: 10px 15px; /* Add padding for touch-friendly buttons */
      margin-top: 15px; /* Add spacing above button */
    }
  }
  
  /* Tablet Styling (570px to 1024px) */
  @media (min-width: 570px) and (max-width: 1024px) {
    .home-content {
      text-align: justify; /* Keep text justified for clean layout */
      padding: 15px; /* Add moderate padding for tablets */
    }
  
    .home-content p {
      font-size: 1.1rem; /* Adjust font size for better readability */
      margin-bottom: 15px; /* Add spacing for a clean layout */
    }
  
    .telephone {
      font-size: 1.1rem; /* Slightly larger button text */
      padding: 10px 20px; /* Adjust padding for better touch usability */
      margin-top: 20px; /* Add more spacing above button */
    }
  }
  